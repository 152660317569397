import { HashRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import { About } from "./About";
import { Alpinism } from "./projects/alpinism/Alpinism";
import { SuperSecretNewProject } from "./projects/superSecretNewProject/SuperSecretNewProject";
import { FlexColumn as FlexColumnBase, FlexRow } from "./components/Flex/Flex";
import { MiniP } from "./components/Typography/Typography";
import "./App.css";
import { Preview } from "./projects/Preview";
import Alpinism1 from "./images/alpinism/1.png";
import Alpinism2 from "./images/alpinism/2.png";
import Alpinism3 from "./images/alpinism/3.png";
import Alpinism7 from "./images/alpinism/7.png";
import Alpinism5 from "./images/alpinism/5.png";
import Chain1 from "./images/chain/1.png";
import Chain2 from "./images/chain/2.png";
import Chain3 from "./images/chain/3.png";
import Chain4 from "./images/chain/4.png";
import Chain5 from "./images/chain/5.png";
import Secret1 from "./images/super-secret-new-project/1.png";
import Secret2 from "./images/super-secret-new-project/2.png";
import Secret5 from "./images/super-secret-new-project/5.png";
import Secret7 from "./images/super-secret-new-project/7.png";
import Secret8 from "./images/super-secret-new-project/8.png";
import { Generator } from "./components/Generator";

const FlexColumn = styled(FlexColumnBase)(() => ({
  alignItems: "flex-start",
  textAlign: "left",
  flexBasis: "50%",
  fontSize: 13,
  paddingRight: "1rem",
  flexWrap: "wrap",
}));

const Home = () => (
  <>
    <FlexRow
      style={{ maxWidth: 600, minWidth: 400, flex: 1, paddingBottom: "2rem" }}
    >
      <FlexColumn style={{ flexBasis: "100%" }}>
        <p>
          <h1>Hey- I'm drey</h1>
          <MiniP>I like making generative art and artistic systems.</MiniP>
          <MiniP>
            <Link to="/about">Read more about me here</Link>
          </MiniP>
          <MiniP>
            <b>I hope you'll enjoy something I've made. 💙 </b>
          </MiniP>
        </p>
      </FlexColumn>
    </FlexRow>
    <FlexRow
      style={{
        maxWidth: 600,
        minWidth: 400,
        flex: 1,
        paddingTop: "2rem",
        borderTop: "1px solid #ddd",
      }}
    >
      <FlexColumn>
        <h1>Projects</h1>
        {/* <Preview
          name="super secret new project"
          link="/super-secret-new-project"
          images={[Secret1, Secret2, Secret7, Secret8, Secret5]}
        /> */}
        <Preview
          name='36" Chain'
          link="/thirty-six-inch-chain/generator"
          images={[Chain2, Chain1, Chain4, Chain5, Chain3]}
        />
        <Preview
          name="Alpinism"
          link="/alpinism"
          platformName="fx(hash)"
          platformLink="https://www.fxhash.xyz/generative/slug/alpinism"
          images={[Alpinism2, Alpinism1, Alpinism3, Alpinism7, Alpinism5]}
        />
        <Preview name="7 more to be added... 😅" />
      </FlexColumn>
    </FlexRow>
  </>
);

const Container = styled(FlexColumn)(() => ({
  alignItems: "center",
  maxWidth: 600,
  flex: 1,
}));

const App = () => (
  <div className="App">
    <Container>
      <HashRouter>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/alpinism" element={<Alpinism />} />
          <Route
            path="/thirty-six-inch-chain/generator"
            element={
              <Generator
                src={`${process.env.PUBLIC_URL}/thirtySixInchChain/index.html`}
              />
            }
          />
          <Route
            path="/macondo/generator"
            element={
              <Generator
                src={`${process.env.PUBLIC_URL}/macondo/index.html`}
              />
            }
          />
          {/* <Route
            path="/super-secret-new-project"
            element={<SuperSecretNewProject />}
          />
          <Route
            path="/super-secret-new-project/generator"
            element={
              <Generator
                src={`${process.env.PUBLIC_URL}/superSecretNewProject/index.html`}
              />
            }
          /> */}
          <Route path="/" element={<Home />} />
        </Routes>
      </HashRouter>
    </Container>
  </div>
);

export default App;
