import React from "react";
import styled from "@emotion/styled";
import { FlexColumn, FlexRow } from "../../components/Flex/Flex";
import { MiniP } from "../../components/Typography/Typography";
import Modal from "react-modal";
import Img1 from "../../images/alpinism/1.png";
import Img2 from "../../images/alpinism/2.png";
import Img3 from "../../images/alpinism/3.png";
import Img4 from "../../images/alpinism/4.png";
import Img5 from "../../images/alpinism/5.png";
import Img6 from "../../images/alpinism/6.png";
import Img7 from "../../images/alpinism/7.png";
import Img9 from "../../images/alpinism/9.png";
import Img10 from "../../images/alpinism/10.png";

const Image = styled.img(() => ({
  maxWidth: 180,
  marginLeft: ".5rem",
  marginRight: ".5rem",
  marginBottom: ".5rem",
  cursor: "pointer",
}));

const Container = styled(FlexColumn)(() => ({
  maxWidth: 650,
}));

const ImgContainer = styled(FlexRow)(() => ({
  flexWrap: "wrap",
}));

const ModalImageContainer = styled.div(() => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  overflow: "hidden",
  "& > img": {
    display: "flex",
    maxHeight: "100%",
    objectFit: "contain",
  },
}));

const ModalCloseButton = styled.button(() => ({
  background: "none",
  border: "none",
  fontSize: 20,
  position: "absolute",
  top: 20,
  right: 20,
  cursor: "pointer",
  padding: 20,
}));

export const Alpinism = () => {
  const images = [Img1, Img2, Img3, Img7, Img5, Img6, Img4, Img9, Img10];

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [currentImageIndex, setCurrentImageIndex] = React.useState();
  const currentImage = images[currentImageIndex];

  React.useEffect(() => {
    if (currentImageIndex !== (null || undefined)) {
      openModal();
    } else {
      closeModal();
    }
  }, [currentImageIndex]);

  React.useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isModalOpen) return;
      switch (e.key) {
        case "ArrowLeft":
          setCurrentImageIndex((x) => (x - 1 < 0 ? images.length - 1 : x - 1));
          break;
        case "ArrowRight":
          setCurrentImageIndex((x) => (x + 1) % images.length);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [images.length, isModalOpen, currentImageIndex]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setCurrentImageIndex()}
        style={{
          overlay: { background: "rgba(255, 255, 255, .9)" },
          content: { background: "none", border: "none", padding: 0 },
        }}
      >
        <ModalCloseButton onClick={() => setCurrentImageIndex()}>
          X
        </ModalCloseButton>
        <ModalImageContainer>
          <img alt="cathedral" src={currentImage} />
        </ModalImageContainer>
      </Modal>
      <Container>
        <h1>Alpinism</h1>
        <h3>
          <a
            href="https://www.fxhash.xyz/generative/slug/alpinism"
            target="_blank"
            rel="noreferrer"
          >
            fx(hash)
          </a>
        </h3>
        <h3>Aug 2022</h3>
        <p>
          <MiniP>
            “Only those who will risk going too far can possibly find out how
            far one can go.” - T.S. Eliot
          </MiniP>
          <MiniP>---</MiniP>
          <MiniP>
            Alpinism is a tribute to the rugged and wild landscapes that rise
            around us. Which may at once intrigue, impress, intimidate, and
            inspire.
          </MiniP>
          <MiniP>
            I am captivated by the mythology of these settings. Where natural
            shapes appear fantastical. A ridge line drawn by pen meets sky
            poured out from neon gradient. Where observation is made through the
            lens of imagination.
          </MiniP>
          <MiniP>
            Each piece begins with the concept of a mountain landscape. A number
            of ranges are drawn by pen in one of three styles. Within each style
            is a spectrum of variation that produces different drawn qualities.
            The backdrop may be an imagined sky, an impossible sunset, a piece
            of parchment. A testament to the interpreter’s memory of the scene.
          </MiniP>
        </p>
        <ImgContainer>
          {images.map((src, i) => (
            <Image
              alt="cathedral"
              src={src}
              onClick={() => setCurrentImageIndex(i)}
            />
          ))}
        </ImgContainer>
      </Container>
    </>
  );
};
