import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { FlexColumn as FlexColumnBase, FlexRow } from "./components/Flex/Flex";
import { MiniP } from "./components/Typography/Typography";
import "./App.css";

const FlexColumn = styled(FlexColumnBase)(() => ({
  alignItems: "flex-start",
  textAlign: "left",
  flexBasis: "50%",
  fontSize: 13,
  paddingRight: "1rem",
  flexWrap: "wrap",
}));

export const About = () => (
  <FlexRow>
    <FlexColumn style={{ flex: 1 }}>
      <p>
        <b>Hey- I'm drey</b>
      </p>
      <p>
        <MiniP>A nickname I had as a kid.</MiniP>
        <MiniP>
          It reminds me of a simpler time, when we were free to be creative and
          trust our imaginations.
        </MiniP>
        <MiniP>
          I began my career as a visual designer and transitioned to software
          engineering. I've worked as a FE software engineer for 10 years.
        </MiniP>
        <MiniP>
          I started working on artistic systems during COVID lockdowns. As a way
          to stay sane. I started by learning 3D rendering. I saw things from my
          imagination become real.
        </MiniP>
        <MiniP>
          Using the skills I know to create something beautiful. It energized
          me.
        </MiniP>
        <MiniP>
          I like to focus on the interplay of colors. Together with chaotic
          geometries, subdivisions, glitches, and particle systems. I'm inspired
          by nature and literature.
        </MiniP>
        <MiniP>
          I prefer a long-form approach. Ensuring the parameter space and output
          range is interesting, aesthetic, thematic yet unique.
        </MiniP>
      </p>
      <p>
        <MiniP>
          <b>I hope you'll enjoy something I've made. 💙 </b>
        </MiniP>
      </p>
    </FlexColumn>

    {/* <h1>Sketches</h1> */}
  </FlexRow>
);
