import React from "react";
import styled from "@emotion/styled";
import { FlexColumn, FlexRow } from "../../components/Flex/Flex";
import { MiniP } from "../../components/Typography/Typography";
import Modal from "react-modal";
import Img1 from "../../images/super-secret-new-project/1.png";
import Img2 from "../../images/super-secret-new-project/2.png";
import Img3 from "../../images/super-secret-new-project/3.png";
import Img4 from "../../images/super-secret-new-project/4.png";
import Img5 from "../../images/super-secret-new-project/5.png";
import Img6 from "../../images/super-secret-new-project/6.png";
import Img7 from "../../images/super-secret-new-project/7.png";
import Img9 from "../../images/super-secret-new-project/9.png";

const Image = styled.img(() => ({
  maxWidth: 180,
  marginLeft: ".5rem",
  marginRight: ".5rem",
  marginBottom: ".5rem",
  cursor: "pointer",
}));

const Container = styled(FlexColumn)(() => ({
  maxWidth: 650,
}));

const ImgContainer = styled(FlexRow)(() => ({
  flexWrap: "wrap",
}));

const ModalImageContainer = styled.div(() => ({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  overflow: "hidden",
  "& > img": {
    display: "flex",
    maxHeight: "100%",
    objectFit: "contain",
  },
}));

const ModalCloseButton = styled.button(() => ({
  background: "none",
  border: "none",
  fontSize: 20,
  position: "absolute",
  top: 20,
  right: 20,
  cursor: "pointer",
  padding: 20,
}));

export const SuperSecretNewProject = () => {
  const images = [Img1, Img2, Img3, Img7, Img5, Img9];

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [currentImageIndex, setCurrentImageIndex] = React.useState();
  const currentImage = images[currentImageIndex];

  React.useEffect(() => {
    if (currentImageIndex !== (null || undefined)) {
      openModal();
    } else {
      closeModal();
    }
  }, [currentImageIndex]);

  React.useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isModalOpen) return;
      switch (e.key) {
        case "ArrowLeft":
          setCurrentImageIndex((x) => (x - 1 < 0 ? images.length - 1 : x - 1));
          break;
        case "ArrowRight":
          setCurrentImageIndex((x) => (x + 1) % images.length);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [images.length, isModalOpen, currentImageIndex]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setCurrentImageIndex()}
        style={{
          overlay: { background: "rgba(255, 255, 255, .9)" },
          content: { background: "none", border: "none", padding: 0 },
        }}
      >
        <ModalCloseButton onClick={() => setCurrentImageIndex()}>
          X
        </ModalCloseButton>
        <ModalImageContainer>
          <img alt="cathedral" src={currentImage} />
        </ModalImageContainer>
      </Modal>
      <Container>
        <h1>Super secret new project</h1>
        <h3>Sept 2022</h3>
        <p>
          <MiniP>
            This project is new and super secret. It is still a WIP that I plan
            to finish during Sept 2022.
          </MiniP>
          <MiniP>
            It is inspired by my favorite novel, 100 Years of Solidtude by
            Gabriel Garcia Marquez.
          </MiniP>
          <MiniP>
            The themes of magic, discovery, elemental/primitive vs. technology,
            pathways, determinism, proximity.
          </MiniP>
          <MiniP>
            In this drawn piece, many types of movers flow through the system.
            These movers can be ellipses, slinkys, ladders, leaves, rocks, and
            more.
          </MiniP>
          <MiniP>
            The system first decides how close these elements can be to one
            another. They may overlap to a significant degree, or they may never
            touch anything else at all. With that in mind, the elements move and
            draw, leaving a trail of history which acts the basis for a packing
            algorithm.
          </MiniP>
          <MiniP>
            The algorithm continues filling the piece with elements. The field
            they move through may be stratified or disrupted. When an element
            enters a disrupted field, it is reduced to its primitive form, and
            drawn as a simple marker stroke.
          </MiniP>
          <MiniP>
            With these approaches, I seek to explore the themes of technology
            vs. simplicity that are central to the novel
          </MiniP>
        </p>
        <ImgContainer>
          {images.map((src, i) => (
            <Image
              alt="cathedral"
              src={src}
              onClick={() => setCurrentImageIndex(i)}
            />
          ))}
        </ImgContainer>
      </Container>
    </>
  );
};
