import styled from "@emotion/styled";

export const FlexRow = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
}));

export const FlexColumn = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));
