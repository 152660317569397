import styled from "@emotion/styled";

const Iframe = styled.iframe(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  width: "100vw",
  height: "100vh",
}));

export const Generator = ({ src }) => <Iframe title="generator" src={src} />;
