import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { FlexColumn, FlexRow } from "../components/Flex/Flex";

const Image = styled.img(() => ({
  maxWidth: 100,
}));

export const Preview = ({ name, link, platformName, platformLink, images }) => (
  <FlexRow style={{ padding: "1rem 0" }}>
    <FlexColumn style={{ flex: 1 }}>
      <FlexRow style={{ justifyContent: "space-between" }}>
        {name && !link && name}
        {name && link && <Link to={link}>{name}</Link>}
        {platformName && platformLink && (
          <a href={platformLink} rel="noreferrer" target="_blank">
            {platformName}
          </a>
        )}
      </FlexRow>
      {images?.length && (
        <FlexRow>
          <FlexRow
            style={{
              margin: "1rem 0",
              justifyContent: "space-between",
              gap: ".5rem",
            }}
          >
            {images.map((src, i) => (
              <Image alt="alpinism" src={src} />
            ))}
          </FlexRow>
        </FlexRow>
      )}
    </FlexColumn>
  </FlexRow>
);
